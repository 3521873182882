@font-face {
  font-family: psb;
  src: url("https://nkpl.namishkumar.in/Product_Sans_Bold.ttf");
}
@font-face {
  font-family: psr;
  src: url("https://nkpl.namishkumar.in/psr.ttf");
}
@font-face {
  font-family: quartzo;
  src: url("./quartzo.ttf");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}
.QuoteHead {
  font-family: psb;
}
.Quartzo {
   font-family: quartzo;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.QuoteBox {
  border-radius: 15px;
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
   background-color: #ff0077;
   color: white;
  margin-top: 20px; 
}

button {
   background-color: black;
   border-radius: 360px;
   font-size: 20px;
   color: white;
   padding: 18px;
   border: none;
   outline: none;
   width: 10em;
   font-family: psr;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}
.CardOptions {
   border-radius: 360px;
   padding: 5px;
   background-color: transparent;
   transition: 0.45s;
}
.CardOptions:hover {
  padding: 5px;
  border-radius: 360px;
  background-color: #000000;
  transition: 0.45s;
}
.CardOptions:active {
  padding: 5px;
  border-radius: 360px;
  background-color: #000000;
  transition: 0.45s;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.hidden {
  display: none;
}
.modal-header {
  padding: 2px 16px;
  font-family: psb;
  background-color: #ff0077;
  color: white;
  font-family: psb;
}

/* Modal Body */
.modal-body {padding: 2px 16px; font-family: psr;}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #ff0077;
  color: white;
  font-family: psr;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  font-family: psr;
  animation-duration: 0.4s
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}
.dropbtn {
  cursor: pointer;
}


/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  float:right;
  display: inline-block;
  margin-left: 0px;
  font-family: psr;
  border-radius: 15px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 15px;
  font-family: psr;
  font-size: 20px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: psr;
  border-radius: 15px;
  transition: 0.45s;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd; transition: 0.45s;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
.hide{display: none;}


